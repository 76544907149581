import {ref, computed, watch} from '@vue/composition-api'
// import { title } from '@core/utils/filter'
import {useToast} from 'vue-toastification/composition'
import ToastificationContent
  from '@core/components/toastification/ToastificationContent.vue'
import moment from 'moment-timezone'
import store from '@/store'
import router from '@/router'
import {useUtils as useI18nUtils} from "@core/libs/i18n";
import { debounce } from 'lodash'

export default function useList() {
  const {t} = useI18nUtils();
  const trans = {
    "messages.error.server_error": t("messages.error.server_error"),
  };
  const refListTable = ref(null)

  const toast = useToast()

  store.dispatch('tenant/getAll')

  const tenantOptions = computed(() => store.getters['tenant/getSelectedItems'])
  const tenantFilter = ref(null)

  if (router.currentRoute.params.id_tenant) tenantFilter.value = parseInt(router.currentRoute.id_tenant)

  const tableColumns = [{
    label: 'swift_account.id_external',
    key: 'id_external',
    sortable: true
  }, {
    label: 'swift_account.name',
    key: 'name',
    sortable: true
  }, {
    label: 'swift_account.is_enabled',
    key: 'is_enabled',
    sortable: true
  }, {
    label: 'swift_account.is_monitored',
    key: 'is_monitored',
    sortable: true
  }, {
    label: 'swift_account.retention',
    key: 'retention',
    sortable: true
  }, {
    label: 'swift_account.brand_id',
    key: 'brand_id',
    sortable: true
  }, {
    label: 'swift_account.language',
    key: 'language',
    sortable: true
  }, {
    label: 'swift_account.creation_time',
    key: 'creation_time',
    sortable: true
  }, {label: 'swift_account.id_parent', key: 'parent.name', sortable: true}, {
    label: 'date.created_at',
    key: 'created_at',
    sortable: true,
    formatter: 'formatDateAssigned'
  }, {
    label: 'date.updated_at',
    key: 'updated_at',
    sortable: true,
    formatter: 'formatDateAssigned'
  },

  ]


  const perPage = ref(10)
  const totalList = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('id')
  const isSortDirDesc = ref(true)

  const dataMeta = computed(() => {
    const localItemsCount = refListTable.value ? refListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalList.value,
    }
  })


  const fetchList = (ctx, callback) => {
    store.dispatch('swift_account/getAll', {
      q: searchQuery.value,
      max: perPage.value,
      'page[n]': currentPage.value,
      sortBy: sortBy.value,
      sortDesc: isSortDirDesc.value,
      id_tenant: tenantFilter.value,
    })
      .then(response => {
        callback(response.data)
        totalList.value = response.meta.total
      })
      .catch(() => {
        toast({
          component: ToastificationContent, props: {
            title: trans["messages.error.server_error"],
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  const formatDateAssigned = value => {
    const formattedDate = moment(value).format('DD/MM/YYYY HH:mm:ss')
    return formattedDate
  }

  const refetchData = () => {
    refListTable.value.refresh()
  }

  watch([ tenantFilter, currentPage, perPage ], () => {
    refetchData()
  })

  watch([ searchQuery ], debounce(() => {
    refetchData()
  }, 500))

  return {
    tableColumns,
    perPage,
    currentPage,
    totalList,
    fetchList,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refListTable,
    formatDateAssigned,
    tenantOptions,
    tenantFilter,
    refetchData,
  }
}
