<template>
  <div>
    <!-- Breadcrumbs -->
    <Breadcrumb
      :nameNav="nameNav"
      :itemFilter="itemFilter"
      :searchQuery="searchQuery"
      @itemFilterSearch="addItemFilterSearch"
    />
    <!-- Toastification loading content export -->
    <toastification-loading-content ref="loadingToast"/>

    <!-- Table Container Card -->
    <b-card class="m-2" no-body>
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label class="text-capitalize">{{ $t("table.show") }}</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label class="text-capitalize">{{ $t("table.entries") }}</label>
          </b-col>

          <!-- Search -->
          <b-col cols="12" md="6">
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                :placeholder="$t('table.search')"
              />
              <b-button
                @click="exportExcel()"
                variant="success"
                class="text-capitalize"
                type="button"
                :disabled="buttonExport"
              >
                <feather-icon icon="DownloadCloudIcon" size="15"/>
              </b-button>
            </div>
          </b-col>
        </b-row>
      </div>

      <b-table
        ref="refListTable"
        class="position-relative"
        :items="fetchList"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        :empty-text="$t('no_record_found')"
        :sort-desc.sync="isSortDirDesc"
      >
        <template #head()="data">
          <span> {{ $tc(data.label, 1) }}</span>
        </template>

        <template #head(name)="data">
          <span> {{ $tc(data.label, 2) }}</span>
        </template>

        <template #head(description)="data">
          <span> {{ $tc(data.label, 2) }}</span>
        </template>

        <template #head(tenant)="data">
          <span> {{ $tc(data.label, 2) }}</span>
        </template>

        <template #head(created_at)="data">
          <span> {{ $tc(data.label, 2) }}</span>
        </template>

        <template #head(updated_at)="data">
          <span> {{ $tc(data.label, 2) }}</span>
        </template>

        <template #head(active)="data">
          <span> {{ $tc(data.label, 2) }}</span>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted text-capitalize">{{
                $t("table.showing", {
                  from: dataMeta.from,
                  to: dataMeta.to,
                  of: dataMeta.of,
                })
              }}</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <Pagination
              :totalList="totalList"
              :currentPage="currentPage"
              @updatePagination="update"
              :perPage="perPage"
            >
            </Pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BTable,
  BButton,
  BDropdown,
  BDropdownItem,
  BPagination,
} from "bootstrap-vue";
import vSelect from "vue-select";
import {ref} from "@vue/composition-api";
import useList from "./useList";
import {useToast} from "vue-toastification/composition";
import ToastificationContent
  from "@core/components/toastification/ToastificationContent.vue";
import store from "@/store";
import {useUtils as useI18nUtils} from "@core/libs/i18n";
import Breadcrumb from "@/components/Breadcrumb.vue";
import ToastificationLoadingContent
  from "@core/components/toastification/ToastificationLoadingContent.vue";
import Pagination from "@/components/Pagination.vue";

export default {
  components: {
    Pagination,
    Breadcrumb,
    ToastificationLoadingContent,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect,
  },

  setup(_, context) {
    //start pagination
    const update = (data) => {
      currentPage.value = data;
    };
    //end pagination

    // start export
    const loadingToast = ref(null);
    const action = ref("list");
    const {t} = useI18nUtils();
    const trans = {
      "messages.export.download": t("messages.export.download"),
      "messages.export.Generating_file": t("messages.export.Generating_file"),
      "messages.error.server_error": t("messages.error.server_error"),
      "messages.delete.title": t("messages.delete.title"),
      "messages.delete.body": t("messages.delete.body"),
      "actions.delete": t("actions.delete"),
      "actions.cancel": t("actions.cancel"),
    };
    const exportExcel = () => {
      action.value == "export";
      buttonExport.value = true;
      loadingToast.value.show(
        trans["messages.export.download"],
        trans["messages.export.Generating_file"],
        "secondary"
      );
      store
        .dispatch("swift_account/export", {
          q: searchQuery.value,
          max: perPage.value,
          'page[n]': currentPage.value,
          sortBy: sortBy.value,
          sortDesc: isSortDirDesc.value,
          id_tenant: tenantFilter.value,
          action: "export",
        })
        .then((response) => {
          console.log(response);
        })
        .catch(() => {
          toast({
            component: ToastificationContent,
            props: {
              title: trans["messages.error.server_error"],
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        })
        .finally(() => {
          loadingToast.value.close();
          buttonExport.value = false;
        });
    };
    const buttonExport = ref(false);
    // end export

    // start breadcrumbs
    const itemFilter = ref();
    const addItemFilter = (data) => {
      itemFilter.value = data;
    };
    const addItemFilterSearch = (data) => {
      data.forEach((element) => {
        if (nameNav.value === element.text) {
          searchQuery.value = element.searchQuery;
        }
      });
    };
    const nameNav = ref("swift_account.title");
    // end breadcrumbs
    const isSidebarActive = ref(false);
    const itemEdit = ref({});
    const isAdd = ref(false);

    const openEdit = (item) => {
      isSidebarActive.value = true;
      itemEdit.value = item;
      isAdd.value = false;
    };


    const toast = useToast();

    const {
      fetchList,
      tableColumns,
      perPage,
      currentPage,
      totalList,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refListTable,
      refetchData,
      formatDateAssigned,
      tenantOptions,
      tenantFilter,
    } = useList();

    const confirmDelete = function (id) {
      const self = this;
      const storeInner = store;
      this.$swal({
        title: context.parent.$i18n.t("messages.delete.title"),
        text: context.parent.$i18n.t("messages.delete.body"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: context.parent.$i18n.t("actions.delete"),
        cancelButtonText: context.parent.$i18n.t("actions.cancel"),
        customClass: {
          confirmButton: "btn btn-primary text-capitalize",
          cancelButton: "btn btn-outline-danger ml-1 text-capitalize",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          storeInner
            .dispatch("center/delete", id)
            .then((response) => {
              if (response.success) {
                toast({
                  component: ToastificationContent,
                  props: {
                    title: response.message,
                    icon: "CheckIcon",
                    variant: "success",
                  },
                });
                self.refetchData();
              } else {
                toast(
                  {
                    component: ToastificationContent,
                    props: {
                      title: response.message,
                      icon: "AlertTriangleIcon",
                      variant: "danger",
                    },
                  },
                  {
                    timeout: 10000,
                  }
                );
              }
            })
            .catch((response) => {
              toast(
                {
                  component: ToastificationContent,
                  props: {
                    title: response.response.data.message,
                    icon: "AlertTriangleIcon",
                    variant: "danger",
                  },
                },
                {
                  timeout: 10000,
                }
              );
            });
        }
      });
    };

    const openAdd = () => {
      isAdd.value = true;
      isSidebarActive.value = true;
    };

    return {
      loadingToast,
      action,
      exportExcel,
      buttonExport,
      itemFilter,
      addItemFilter,
      addItemFilterSearch,
      nameNav,
      isSidebarActive,
      isAdd,
      itemEdit,
      openEdit,
      openAdd,
      confirmDelete,
      fetchList,
      tableColumns,
      perPage,
      currentPage,
      totalList,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refListTable,
      refetchData,
      formatDateAssigned,
      tenantOptions,
      tenantFilter,
      update
    };
  },
};
</script>